import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiResponse } from './types';
import { AxiosService } from 'api/axiosService';
import { components } from '../openapi-types';
import { PaginatedListState, serializeParamsForQuery } from 'component/hooks/usePaginatedListState';

export type VerificationRecord = components['schemas']['ValidationRecord'];

interface SetDomainData {
  domain: string;
}

export const useSetMainDomain = (siteId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [`set-main-domain-for-${siteId}`],

    mutationFn: async (data: SetDomainData) =>
      await AxiosService.post<ApiResponse<Array<VerificationRecord>>>(
        `sites/${siteId}/maindomain`,
        data
      ),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`siteDetail${siteId}`],
      });
    },
  });
};

interface UpdateSSLValidationData {
  validation_method: 'http' | 'txt';
}

export const useUpdateSSLValidation = (siteId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [`update-validation-type-for-${siteId}`],

    mutationFn: async (data: UpdateSSLValidationData) =>
      await AxiosService.patch<ApiResponse<Array<VerificationRecord>>>(
        `sites/${siteId}/maindomain`,
        data
      ),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`siteDetail${siteId}`],
      });
    },
  });
};

interface UpdateGoLiveData {
  hide_go_live: 0 | 1;
}

export const useUpdateGoLiveMode = (siteId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [`update-go-live-mode-for-${siteId}`],

    mutationFn: async (data: UpdateGoLiveData) =>
      await AxiosService.patch<ApiResponse<Array<VerificationRecord>>>(
        `sites/${siteId}/maindomain`,
        data
      ),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`siteDetail${siteId}`],
      });
    },
  });
};

export const useForceValidationRecheck = (siteId: string, force: boolean = false) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [`force-validation-recheck-for-${siteId}`],

    mutationFn: async () =>
      await AxiosService.get<ApiResponse<Array<VerificationRecord>>>(
        `sites/${siteId}/maindomain/recheck${force ? '?force=true' : ''}`
      ),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`siteDetail${siteId}`],
      });
    },
  });
};

interface DeleteDomainData {
  id: number;
}

export const useDeleteDomain = (siteId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [`delete-additional-domain-${siteId}`],

    mutationFn: async (data: DeleteDomainData) =>
      await AxiosService.delete<ApiResponse<Array<VerificationRecord>>>(
        `sites/${siteId}/domains/${data.id}`
      ),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`siteDetail${siteId}`],
      });
    },
  });
};

export type SiteDomain = components['schemas']['MainDomain'];

export const useSiteDomains = (siteId: string | number, state: PaginatedListState) => {
  const serializedParams = serializeParamsForQuery(state);

  return useQuery({
    queryKey: ['sites/{id}/domains', siteId, serializedParams],

    queryFn: async () => {
      return await AxiosService.get<components['schemas']['GetAdditionalDomainsResponse']>(
        `sites/${siteId}/domains?${serializedParams}`
      );
    },

    retry: 0,
    staleTime: 5000,
    gcTime: 10,
  });
};
