export const getCountryName = (countryCode: string) => {
  let countryName: string;
  try {
    countryName = new Intl.DisplayNames(['en'], { type: 'region' }).of(countryCode) || countryCode;
  } catch {
    countryName = countryCode;
  }

  return countryName;
};
