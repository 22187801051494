export function getTickFormatForDuration(duration: string) {
  switch (duration) {
    case '30m':
      return '%I:%M:%S %p';
    case '6h':
    case '12h':
    case '24h':
    case '72h':
      return '%I:%M %p';
    default:
      return '%m/%d';
  }
}
