import { forwardRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, Dialog, Grid, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import ControlledEditor, { useMonaco } from '@monaco-editor/react';
import { useViewSiteFile, useDownloadSiteFile, usePutSiteFile } from 'api/site';
import { useQueryParams } from 'component/hooks/queryParams';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload, faArrowToBottom } from '@fortawesome/pro-regular-svg-icons';
import { ProgressiveButton } from 'component/base/ProgressiveButton';
import he from 'he';
import { isInIframe } from 'utils/iframe';

const PREFIX = 'SiteFileContent';

const classes = {
  dialog: `${PREFIX}Dialog`,
  header: `${PREFIX}Header`,
  filename: `${PREFIX}Filename`,
  actions: `${PREFIX}Actions`,
};

const StyledDialog = styled(Dialog)({
  [`&.${classes.dialog}`]: {
    margin: '0',
    '& .MuiDialog-paperFullScreen': {
      overflow: 'hidden',
    },
    '& .MuiDialog-paper': {
      margin: '0',
    },
  },
  [`& .${classes.header}`]: {
    flex: '0 0 auto',
    padding: '1.25rem 1rem',
  },
  [`& .${classes.filename}`]: {
    verticalAlign: 'top',
  },
  [`& .${classes.actions}`]: {
    textAlign: 'right',
  },
});

const Transition = forwardRef(function Transition(
  props: TransitionProps & { readonly children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Editor = ({
  fileContent,
  filename,
  onChange,
}: {
  readonly fileContent: string;
  readonly filename?: string;
  readonly onChange: (value: string) => unknown;
}) => {
  const monaco = useMonaco();

  if (!fileContent) {
    return null;
  }

  return (
    <ControlledEditor
      value={fileContent}
      options={{
        scrollBeyondLastLine: false,
        theme: 'rocket-base',
      }}
      onChange={value => {
        value && onChange(value);
      }}
      beforeMount={monaco => {
        monaco.editor.defineTheme('rocket-base', {
          base: 'vs',
          inherit: true,
          rules: [
            {
              background: 'EEEEEEEB',
              token: '',
            },
            {
              foreground: '009933',
              token: 'string',
            },
            {
              foreground: '0066ff',
              token: 'constant.numeric',
            },
            {
              foreground: 'ff0080',
              token: 'string.regexp',
            },
            {
              foreground: '0000ff',
              token: 'keyword',
            },
            {
              foreground: '9700cc',
              token: 'constant.language',
            },
            {
              foreground: '990000',
              token: 'support.class.exception',
            },
            {
              foreground: 'ff8000',
              token: 'entity.name.function',
            },
            {
              fontStyle: 'bold underline',
              token: 'entity.name.type',
            },
            {
              fontStyle: 'italic',
              token: 'variable.parameter',
            },
            {
              foreground: '0066ff',
              fontStyle: 'italic',
              token: 'comment',
            },
            {
              foreground: 'ff0000',
              background: 'e71a114d',
              token: 'invalid',
            },
            {
              background: 'e71a1100',
              token: 'invalid.deprecated.trailing-whitespace',
            },
            {
              foreground: '000000',
              background: 'fafafafc',
              token: 'text source',
            },
            {
              foreground: '0033cc',
              token: 'meta.tag',
            },
            {
              foreground: '0033cc',
              token: 'declaration.tag',
            },
            {
              foreground: '6782d3',
              token: 'constant',
            },
            {
              foreground: '6782d3',
              token: 'support.constant',
            },
            {
              foreground: '3333ff',
              fontStyle: 'bold',
              token: 'support',
            },
            {
              fontStyle: 'bold',
              token: 'storage',
            },
            {
              fontStyle: 'bold underline',
              token: 'entity.name.section',
            },
            {
              foreground: '000000',
              fontStyle: 'bold',
              token: 'entity.name.function.frame',
            },
            {
              foreground: '333333',
              token: 'meta.tag.preprocessor.xml',
            },
            {
              foreground: '3366cc',
              fontStyle: 'italic',
              token: 'entity.other.attribute-name',
            },
            {
              fontStyle: 'bold',
              token: 'entity.name.tag',
            },
          ],
          colors: {
            'editor.foreground': '#000000',
            'editor.background': '#EEEEEEEB',
            'editor.selectionBackground': '#BAD6FD',
            'editor.lineHighlightBackground': '#0000001A',
            'editorCursor.foreground': '#000000',
            'editorWhitespace.foreground': '#B3B3B3F4',
          },
        });
      }}
      theme="rocket-base"
      defaultPath={monaco ? monaco.Uri.file(filename ?? '') : null}
      onMount={(editor, monaco) => {
        setTimeout(() => {
          try {
            const model = monaco.editor.createModel(
              fileContent,
              undefined, // language
              monaco.Uri.file(filename ?? '') // uri
            );

            editor.setModel(model);
          } catch (e) {
            // error
          }
        });
      }}
    />
  );
};

type Props = {
  readonly siteId: string;
};

export function SiteFileContent({ siteId }: Props) {
  const params = useQueryParams();

  const filename = params.get('filename') ?? undefined;
  const directory = params.get('directory') ?? undefined;
  const { data, isPending } = useViewSiteFile(siteId, filename, directory);
  const downloadSiteFile = useDownloadSiteFile(siteId, filename, directory);
  const navigate = useNavigate();
  const [fileDownloading, setFileDownloading] = useState<boolean>(false);
  const { t } = useTranslation();
  const putSiteSile = usePutSiteFile(siteId);
  const isIframe = isInIframe();
  const [changedContent, setChangedContent] = useState<string | null>(null);
  let content = '';
  try {
    content = he.decode(data?.data.result.content ?? '', {
      strict: true,
    });
  } catch (e) {
    console.error(e);
  }

  const body = (
    <>
      <Box className={classes.header}>
        <Grid container spacing={1} justifyContent="space-between" wrap="nowrap">
          <Grid item>
            <Box display="inline-block" className={classes.filename}>
              <Typography>
                <strong>{t('file_manager')}</strong>
                {' | '}
                {`"${filename}"`}
              </Typography>
            </Box>
          </Grid>
          <Grid item className={classes.actions} alignSelf="flex-start">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                navigate(-1);
              }}
              size="small"
            >
              {t('close')}
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Box display="inline-block" marginRight={3}>
              <ProgressiveButton
                variant="text"
                text={
                  <>
                    <Box display="inline-block" marginRight={0.5}>
                      <FontAwesomeIcon icon={faArrowToBottom} size="sm" />
                    </Box>
                    {t('download')}
                  </>
                }
                onClick={async () => {
                  setFileDownloading(true);
                  const response = await downloadSiteFile();
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', filename ?? '');
                  document.body.appendChild(link);
                  link.click();
                  setFileDownloading(false);
                }}
                isLoading={fileDownloading}
                size="small"
              />
            </Box>
            <Box display="inline-block">
              <ProgressiveButton
                variant="text"
                color="primary"
                onClick={async () => {
                  await putSiteSile.mutateAsync({
                    file: filename ?? '',
                    path: directory ?? '',
                    content: changedContent ?? '',
                  });
                }}
                size="small"
                isLoading={putSiteSile.isPending}
                disabled={putSiteSile.isPending || !changedContent}
                text={
                  <>
                    <Box display="inline-block" marginRight={0.5}>
                      <FontAwesomeIcon icon={faCloudUpload} size="sm" />
                    </Box>
                    {t('save')}
                  </>
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={
          isIframe
            ? {
                height: '300px',
                flex: '1 1 auto',
              }
            : {
                flex: '1 1 auto',
              }
        }
      >
        <Editor
          onChange={value => setChangedContent(value)}
          fileContent={content ?? (isPending ? '' : '// Write file here')}
          filename={filename}
        />
      </Box>
    </>
  );

  if (isIframe) {
    return <Box style={{ overflow: 'hidden' }}>{body}</Box>;
  }

  return (
    <StyledDialog
      fullScreen
      open
      onClose={() => {}}
      TransitionComponent={Transition}
      className={classes.dialog}
    >
      {body}
    </StyledDialog>
  );
}
