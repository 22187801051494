import { cssVar } from 'utils/css';

export const tooltip = {
  '&': {
    backgroundColor: cssVar('--color-white'),
    borderRadius: cssVar('--border-radius'),
    boxShadow: cssVar('--shadow-card'),
    fontSize: '0.75rem',
    padding: '0.625rem',
  },
};
